import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import FAQ from "../components/faq";

export default function Faq() {
  return (
    <Layout>
      <SEO title="FAQ" />
      <FAQ />
    </Layout>
  );
}
