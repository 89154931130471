import React from "react";

export default function FAQ() {
  return (
    <div className="section">
      <div className="center">
        <div className="title text-center">FAQ</div>

        <div className="space-4" />

        <div className="paragraph">
          1. What is the minimum amount of pixels that I can buy?
        </div>
        <div className="paragraph">
          1. Recommended blocks are 10x10 pixels, which sums up to 100€.
        </div>
        <br />
        <div className="paragraph">
          2. How long will the the billboard be online?
        </div>
        <div className="paragraph">
          2. Europe's most famous billboard will be online forever.
        </div>
        <br />
        <div className="paragraph">3. May I advertise my social accounts?</div>
        <div className="paragraph">
          3. Yes. All major social networks are supported. Such as Facebook
          Profile, Facebook Group, Facebook Page, Twitter Account, LinkedIn
          Account, Youtube Channel etc.
        </div>
        <br />
        <div className="paragraph">
          4. Can I design myself the advertisment?
        </div>
        <div className="paragraph">
          4. Yes. We only require a sketch and a link. If design is not provided
          we will user your logo.
        </div>
        <br />
        <div className="paragraph">
          5. Can I choose a specific area of Billboard for my advertisment?
        </div>
        <div className="paragraph">
          5. No. Due to logistic and technical complications we can not offer
          such service!
        </div>
      </div>
    </div>
  );
}
